@use '../config/' as *;
.waf-player {
    background-image: $gradient ;
    padding: 0;
    isolation: isolate;
    @extend %relative;
    @extend %white;
    .waf-head {
        .form-select-box {
            @extend %d-none;
        }
    }
    &::after {
        content: "";
        z-index: var(--z-negative);
        background-attachment: fixed;
        background-size: 100%;
        opacity: 0.5;
        @extend %position-t-l;
        @extend %w-100;
        @extend %h-100;
    }
    &.waf-component {
        @extend %mx-2-neg;
    }
    .card {
        &-info {
            &-wrapper {
                @extend %w-100;
                @extend %white;
                @extend %relative;
                @extend %flex-column;
                @extend %mt-6-neg;
                @extend %pb-6;
                a,
                button {
                    @extend %white;
                }
            }
            &-thumbnail {
                order: -1;
                z-index: var(--z-negative);
                @extend %relative;
                @extend %m-auto;
                .image {
                    width: var(--player-width);
                    aspect-ratio: 1/1;
                    -webkit-mask-image: linear-gradient(to bottom, var(--black) 0%, var(--black) 40%, transparent 94%, transparent 100%);
                    mask-image: linear-gradient(to bottom, var(--black) 0%, var(--black) 40%, transparent 94%, transparent 100%);
                    @extend %m-auto;
                }
                &::after {
                    content: "";
                    background: transparent;
                    width: calc(var(--player-width) + 2rem);
                    height: calc(var(--player-width) + 2rem);
                    position: absolute;
                    top: calc(18% - 1rem);
                    left: -1rem;
                    right: -1rem;
                    margin: auto;
                    border-radius: 50%;
                    border: .1rem dashed var(--white);
                    z-index: var(--z-negative);
                    opacity: 0.4;
                    -webkit-mask-image: linear-gradient(to bottom, var(--black) 0%, var(--black) 15%, transparent 60%, transparent 100%);
                    mask-image: linear-gradient(to bottom, var(--black) 0%, var(--black) 15%, transparent 60%, transparent 100%);
                }
                &::before {
                    content: "";
                    top: 18%;
                    margin: auto;
                    width: var(--player-width);
                    height: var(--player-width);
                    -webkit-mask-image: linear-gradient(to bottom, var(--black) 0%, var(--black) 15%, transparent 60%, transparent 100%);
                    mask-image: linear-gradient(to bottom, var(--black) 0%, var(--black) 15%, transparent 60%, transparent 100%);
                    @extend %club-secondary-bg;
                    @extend %circle-radius;
                    @extend %position-x-center;
                }
            }
        }
        &-number {
            -webkit-text-stroke: 0.1rem hsl(var(--hsl-white)/0.5);
            color: transparent;
            right: var(--space-4);
            @extend %position-t-r;
            @extend %font-50-pb;
        }
        &-name {
            max-width: 27rem;
            @extend %m-auto;
            @extend %uppercase;
            .name {
                @extend %font-18-pr;
            }
            .lname {
                @extend %font-18-pb;
            }
        }
        &-meta {
            &.meta-a {
                @extend %mt-4-neg;
            }
            &.meta-b {
                max-width: 27rem;
                border-top: 0.05rem solid hsl(var(--hsl-white)/0.5);
                padding-block: var(--space-2);
                @extend %flex-n-c;
                @extend %my-2;
                @extend %gap-3;
                @extend %mx-auto;
            }
            &-item {
                flex: 1;
                min-width: max-content;
                @extend %uppercase;
                @extend %flex-column-c-fs;
                @extend %pr-3;
                &:where(:not(:last-child)) {
                    @include separator(80%, y);
                }
            }
            .country {
                .image {
                    width: 3.4rem;
                    height: 2rem;
                    display: inline-block;
                    @extend %mr-2;
                }
                .card-text {
                    max-width: 15rem;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    @extend %gap-2;
                }
            }
        }
        &-label {
            @extend %font-12-pr;
        }
        &-text {
            @extend %font-14-pb;
        }
        &-skills {
            width: max-content;
            margin-left: calc((100% - 27rem)/2);
            @extend %font-8;
            @extend %px-1;
            @extend %white-bg-1;
        }
        &-bio {
            overflow: auto;
            max-height: calc(100% - 5rem);
            @include border(1, white, 5);
            @extend %w-100;
            @extend %quarter-radius;
            @extend %mt-4;
            @extend %p-4;
            &-wrap {
                .card-action {
                    margin-left: calc((100% - 27rem)/2);
                    position: absolute;
                    bottom: 2rem;
                    left: 0;
                    @extend %club-secondary-bg;
                    @extend %text-left;
                    @extend %flex-c-c;
                    @extend %p-1-2;
                    @extend %uppercase;
                    @extend %gap-1;
                    @extend %quarter-radius;
                    .btn-text {
                        @extend %font-10-pb;
                    }
                    &::after {
                        @include icon(chevron-right, 10)
                    }
                }
            }
            &-modal {
                position: fixed;
                top: var(--header-height);
                left: 0;
                height: calc(var(--window-inner-height) - var(--header-height));
                z-index: var(--z-writeup);
                display: none;
                @include backdropBlur(2rem);
                @extend %flex-column-c-c;
                @extend %p-4;
                @extend %w-100;
                @extend %secondary-dark-bg-7;
                .content {
                    font-size: 1.4rem;
                    line-height: 2;
                }
                &.active {
                    display: flex;
                }
                .close {
                    @extend %flex-n-c;
                    @extend %ml-auto;
                    @extend %gap-1;
                    @extend %club-primary;
                    @extend %uppercase;
                    @extend %ml-auto;
                    @extend %white;
                    span {
                        @extend %white;
                    }
                    &::after {
                        @extend %white;
                        @include icon(close);
                    }
                }
            }
            &-title {
                @extend %font-20-pb;
                @extend %uppercase;
            }
            .card-content {
                @extend %font-16;
            }
        }
    }
    .preview-swiper {
        isolation: isolate;
        position: unset;
        max-width: 35rem;
        width: calc(100% - 5rem);
        @extend %mx-auto;
        @extend %px-0;
        @extend %pt-5;
        @extend %pb-10;
        .card-item {
            width: calc(100% /4 - 2rem);
            cursor: pointer;
        }
        .card {
            &-thumbnail {
                width: 5.5rem;
                height: 5.5rem;
                aspect-ratio: 1/1;
                background-image: linear-gradient(to bottom, var(--club-primary), var(--club-varient));
                @include border(1, white);
                @extend %m-auto;
                @extend %circle-radius;
                @extend %hidden;
            }
            &-name {
                min-width: max-content;
                width: 100%;
                bottom: -4.5rem;
                opacity: 0;
                pointer-events: none;
                transition: opacity 300ms;
                @extend %position-b-l;
                @extend %p-1-2;
                @extend %white-bg;
                .name {
                    line-height: 1;
                    @extend %secondary-dark;
                    @extend %font-12-pr;
                    @extend %d-block;
                    @extend %text-center;
                }
                .lname {
                    font-weight: 700;
                }
            }
            &-item {
                @extend %relative;
            }
            &-action {
                @include position-combo(inset);
                @extend %font-0;
            }
        }
    }
    .swiper {
        &-button {
            &-next,
            &-prev {
                top: 5rem;
                margin: 0;
                @extend %transparent-bg;
                @extend %white;
            }
            &-disabled {
                opacity: 0.5;
                &::after {
                    @extend %white;
                }
            }
        }
        &-slide {
            opacity: 0.3;
            transition: opacity 300ms;
            &.active {
                opacity: 1;
            }
            &:hover {
                opacity: 1;
                .card-name {
                    opacity: 1;
                }
            }
        }
    }
    .waf-head {
        .title {
            @extend %d-none;
        }
    }
    .waf-body {
        isolation: isolate;
        @extend %p-2;
    }
    .player-stats {
        &-wrapper {
            @extend %quarter-radius;
        }
        &-title {
            opacity: 0.7;
        }
    }
    .selected-title {
        background-color: transparent;
        @include border(1, white, 5);
        @extend %quarter-radius;
    }
    .stats {
        &-listing {
            .matches,
            .minutes,
            .goals-conceded,
            .assists,
            .fouls,
            .red-cards,
            .yellow-cards,
            .saves {
                .player-stats-value {
                    @extend %flex-n-c;
                    &::before {
                        content: "";
                        background-size: contain;
                        background-repeat: no-repeat;
                        width: 4.5rem;
                        height: 4.5rem;
                        @extend %mr-2-neg;
                        // transform: translate(-3.5rem, -1rem);
                        // @extend %position-center;
                    }
                }
            }
            // when there is svg graph
            .has-graph {
                flex-direction: row;
                background-color: transparent;
                grid-column-end: span 2;
                .stats-item {
                    &-media {
                        width: 11rem;
                        height: 11rem;
                        margin-bottom: 0;
                        flex-shrink: 0;
                    }
                    &-data {
                        flex-direction: row-reverse;
                    }
                }
                .player-stats {
                    &-title {
                        width: 10rem;
                        @extend %text-left;
                        @extend %font-14;
                    }
                    &-value {
                        position: relative;
                        width: 3rem;
                        left: -5rem;
                        @extend %flex-c-n;
                        @extend %ml-4-neg;
                    }
                }
            }
            @each $stats-item in (matches, minutes, goals-conceded, saves, fouls, yellow-cards, red-cards) {
                .#{$stats-item} .player-stats-value::before {
                    @include bgImg("stats-icon/#{$stats-item}.svg")
                }
            }
        }
        &-item {
            @extend %relative;
            &-media {
                @extend %p-1;
            }
            &.span-2 {
                grid-column-end: span 2;
            }
        }
    }
}
@include mq(col-md) {
    .waf-player {
        padding-bottom: var(--space-10);
        &::after {
            @include bgImg("cssimages/players.png");
        }
        .waf-squad .player-image {
            width: 4.5rem;
            height: 4.5rem;
        }
        .waf-body {
            padding: 0;
        }
        .stats {
            &-item {
                &.stats-25 {
                    width: calc(25% - 3 * var(--space-4) / 4);
                }
                &.stats-50 {
                    width: calc(50% - 1 * var(--space-4) / 2);
                }
                &.stats-33 {
                    width: calc((100% / 3) - 2 * var(--space-4) / 3);
                }
                &.stats-20 {
                    width: calc(20% - 4 * var(--space-4) / 5);
                }
            }
        }
        .preview-swiper {
            max-width: 50rem;
        }
        .swiper-button {
            &-prev,
            &-next {
                top: 4rem;
            }
            &-prev {
                left: calc((100% - 60rem) / 2);
            }
            &-next {
                right: calc((100% - 60rem) / 2);
            }
        }
        .card {
            &-info {
                &-wrapper {
                    display: flex;
                    align-items: end;
                    justify-content: center;
                    flex-direction: row;
                    padding: 0;
                    margin: 0;
                }
                &-thumbnail {
                    width: var(--player-width);
                    margin: 0 10rem;
                    order: unset;
                    .image {
                        -webkit-mask-image: linear-gradient(to bottom, var(--black) 0%, var(--black) 70%, transparent 120%);
                        mask-image: linear-gradient(to bottom, var(--black) 0%, var(--black) 70%, transparent 120%);
                    }
                    &::before {
                        -webkit-mask-image: linear-gradient(to bottom, var(--black) 0%, var(--black) 60%, transparent 80%);
                        mask-image: linear-gradient(to bottom, var(--black) 0%, var(--black) 60%, transparent 80%);
                    }
                    &::after {
                        -webkit-mask-image: linear-gradient(to bottom, var(--black) 0%, var(--black) 60%, transparent 80%);
                        mask-image: linear-gradient(to bottom, var(--black) 0%, var(--black) 60%, transparent 80%);
                    }
                }
            }
            &-name {
                margin-block: var(--space-3);
                line-height: 1;
                .name {
                    font-size: 2.4rem;
                    display: block;
                }
            }
            &-skills {
                font-size: 1.2rem;
            }
            &-meta {
                margin: var(--space-4) 0;
                flex-direction: column;
                text-align: left;
                &.meta-a {
                    order: -1;
                }
                &.meta-b {
                    margin: 0;
                    border: 0;
                    gap: 0;
                }
                &-item {
                    width: 100%;
                    padding-block: var(--space-4);
                    &:not(:last-child) {
                        @include separator(100%, x);
                        &::after {
                            top: unset;
                        }
                    }
                }
            }
            &-number {
                line-height: 1;
                padding-block: var(--space-3);
                right: 0;
                font-size: 7rem;
                @include separator(100%, x);
            }
            &-text {
                font-size: 2rem;
            }
            &-skills {
                margin: 0;
            }
            &-bio {
                max-width: calc(var(--container-max-width) * 0.75);
                margin: 0 auto;
                &-wrap {
                    .card-action {
                        position: unset;
                        margin: var(--space-6) 0;
                        padding: var(--space-2);
                        bottom: 0;
                        @include separator(100%, x);
                        &::after {
                            top: var(--space-3-neg);
                            bottom: unset;
                        }
                        .btn-text {
                            font-size: 1.2rem;
                        }
                    }
                    .close {
                        margin-bottom: 1rem;
                        margin-right: calc(var(--container-white-space) + var(--container-max-width)*0.12);
                    }
                }
            }
        }
    }
}