@use './config/' as *;
@forward './main-scss/';
@forward 'form/common';
@forward './squad/players-common';
@forward './squad/player-profile';
@forward './swiper/swiper-bundle';
.waf-player {
    &::after {
        @include bgImg("cssimages/players-m.png");
    }
}
.count-hidden {
    .card-info-wrapper {
        .card-number {
            display: none;
        }
    }
}